/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .comeos-menu nav.mycomeos-menu {
  display: none;
}
.altai-theme-content #bodyView .edit-mode .comeos-menu nav.mycomeos-menu {
  display: block;
  position: relative;
}
.altai-theme-content #bodyView nav.mycomeos-menu {
  background-color: black;
  color: white;
  margin-top: 0;
}
.altai-theme-content #bodyView nav.mycomeos-menu ul {
  list-style-type: none;
  text-align: center;
  padding-top: 11px;
  padding-bottom: 11px;
  margin-bottom: 0;
}
.altai-theme-content #bodyView nav.mycomeos-menu ul li {
  padding: 0 20px;
  display: inline;
  cursor: pointer;
  background-color: black;
  position: relative;
  transition: 100ms background-color ease-in-out;
}
.altai-theme-content #bodyView nav.mycomeos-menu ul li a {
  color: white;
}
.altai-theme-content #bodyView nav.mycomeos-menu ul li .arrow-up {
  width: 10px;
  height: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  left: 0;
  bottom: -20px;
  margin: auto;
  right: 0;
}
.altai-theme-content #bodyView nav.mycomeos-menu ul li:hover {
  color: #EDEDED;
}
.altai-theme-content #bodyView.mycomeosfixed .comeos-menu nav.mycomeos-menu {
  display: block;
  opacity: 1;
}
.altai-theme-content #bodyView.mycomeosfixed nav.mycomeos-menu {
  display: block;
  opacity: 0;
}
@media screen and (max-width: 1100px) {
  .altai-theme-content #bodyView.mycomeosfixed .comeos-menu nav.mycomeos-menu {
    display: none;
  }
  .altai-theme-content #bodyView nav.mycomeos-menu,
  .altai-theme-content #bodyView nav.mycomeos-menu.fixed {
    display: none;
    opacity: 1;
  }
  .altai-theme-content #bodyView nav.mycomeos-menu ul li,
  .altai-theme-content #bodyView nav.mycomeos-menu.fixed ul li {
    display: block;
  }
  .altai-theme-content #bodyView nav.mycomeos-menu ul li .arrow-up,
  .altai-theme-content #bodyView nav.mycomeos-menu.fixed ul li .arrow-up {
    width: 10px;
    height: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid white;
    position: absolute;
    top: 0;
    bottom: -20px;
    margin: auto;
    right: auto;
    bottom: 0;
    z-index: 1;
  }
}
